import { nextTick, onMounted } from 'vue';
import { useStorage } from '@vueuse/core';
import User from '@/services/local/UserService';
import loadScript from '@/helpers/loadScript';

const APP_ID = process.env.VUE_APP_INTERCOM;

export default () => {
	const intercomDisabled = useStorage('intercom-disabled', false);

	onMounted(async () => {
		try {
			if (typeof window.Intercom === 'function') {
				window.Intercom('reattach_activator');
			} else {
				const i = function () {
					// eslint-disable-next-line prefer-rest-params
					i.c(arguments);
				};

				i.q = [];

				i.c = function (args) {
					i.q.push(args);
				};

				window.Intercom = i;

				await loadScript('intercom', `https://widget.intercom.io/widget/${APP_ID}`);
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.warn('Error init Intercom', e);
		}
	});

	const enableChat = async () => {
		if (intercomDisabled.value) {
			return;
		}

		await nextTick();

		try {
			window.Intercom?.('boot', {
				api_base: "https://api-iam.intercom.io",
				app_id: APP_ID,
				name: User?.currentUser?.user?.user_name,
				user_id: User?.currentUser?.user?.account_id,
				email: User?.currentUser?.user?.user_email,
				// TODO убрать перед релизом и поменять в админке
				alignment: 'right',
			});
		} catch (e) {
			// eslint-disable-next-line no-console
			console.warn('Error boot Intercom', e);
		}
	};

	const disableChat = () => {
		window.Intercom?.('shutdown');
	};

	return (show: boolean) => {
		show ? enableChat() : disableChat();
	};
};
